import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

const ErrorBoundary = (props) => {
  const [hasError, setHasError] = useState(false);

  const styles = {
    errorCard: {
      textAlign: "center",
    },
    thankYouMessage: {
      fontSize: "1rem",
      color: props.color,
    },
    container: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    image: {
      width:
        window.innerWidth <= 576
          ? "40px"
          : window.innerWidth <= 768
          ? "50px"
          : window.innerWidth <= 1200
          ? "40px"
          : "50px",
      height:
        window.innerWidth <= 576
          ? "35px"
          : window.innerWidth <= 768
          ? "45px"
          : window.innerWidth <= 1200
          ? "35px"
          : "45px",
    },
    text: {
      fontFamily: "rubik",
      color: "#40BBEC",
      fontSize:
        window.innerWidth <= 576
          ? "24px"
          : window.innerWidth <= 768
          ? "28px"
          : window.innerWidth <= 1200
          ? "24px"
          : "28px",
      fontWeight: 500,
      textTransform: "none",
      marginLeft: "5px",
      marginBottom: 0,
    },
  };

  useEffect(() => {
    if (hasError) {
      setInterval(() => {
        window.location.reload();
      }, 10000);
    } else {
      return;
    }
  }, [hasError]);

  const handleError = () => {
    setHasError(true);
  };
  useEffect(() => {
    window.addEventListener("error", handleError);
    window.addEventListener("unhandledrejection", (event) => {
      handleError({
        error: event.reason,
      });
    });

    return () => {
      window.removeEventListener("error", handleError);
      window.removeEventListener("unhandledrejection", handleError);
      setHasError(false);
    };
  }, []);

  if (hasError) {
    return (
      <Row className="justify-content-center mt-5">
        <Col md="4" lg="4">
          <Card style={styles.errorCard}>
            <CardHeader>
              <div
                style={styles.container}
                className="align-items-center d-flex mt-2 mb-1"
              >
                <img
                  alt="..."
                  className="navbar-brand-img align-items-center d-flex"
                  src={require("assets/img/brand/bulk-logo.png")}
                  style={styles.image}
                />
                <p style={styles.text}>BulkCalendar</p>
              </div>
              <h2>500 Error</h2>
            </CardHeader>
            <CardBody>
              <p style={styles.thankYouMessage}>Something went wrong.</p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  return props.children;
};

export default ErrorBoundary;
