import React, { useEffect, useRef, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useLocation, useNavigate } from "react-router-dom";
import PreviewModal from "./PreviewModal";
import {
  APILoader,
  PlacePicker,
} from "@googlemaps/extended-component-library/react";
import Select, { components } from "react-select";
import {
  attendeeParameter,
  calendarParameter,
  eventTypeList,
} from "../Common/constants";
import { formatDateTime } from "components/CommonFunctions";
import InstructionModal from "components/Common/IntructionModal";
import { getTimeZoneList, getProfileDetail } from "action/Settings/profile";
import { listEmailConfig } from "action/Settings/emailConfiguration";
import { useDispatch, useSelector } from "react-redux";
import {
  createCampaignStep1,
  getCampaignDetails,
  updateCampaign,
  getPlanListForCampaign,
} from "action/campaign/createCampaign";
import NotificationAlert from "react-notification-alert";
import {
  inputBorderColor,
  inputTextColor,
  campaignGuide,
  CAMPAIGN_STATUS_IN_PROGRESS,
} from "components/Common/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EmailEditor from "./EmailEditor";
import Swal from "sweetalert2";
import { clearCampaignDetailsFrmStore } from "action/campaign/createCampaign";
import Warning from "components/Common/Warning";
import EmailConfigModal from "./EmailConfigModal";
import { emailRegex } from "components/CommonFunctions";
import { showInProgressAlert } from "components/CommonFunctions";

function CreateCampaignStep1() {
  const [duration, setDuration] = useState({
    day: 0,
    hour: 0,
    minutes: 0,
  });
  const organizerName = JSON.parse(localStorage.getItem("p"))?.name;
  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const profileDetails = useSelector((state) => state.Profile.profileDetails);
  const [createCampaignFields, setCreateCampaignFields] = useState({
    campaignName: { value: "", isValid: true, errMsg: "", isChanged: false },
    calendarTitle: { value: "", isValid: true, errMsg: "", isChanged: false },
    bookCalendar: { value: "YES", isValid: true, isChanged: false }, //value in YES/NO
    removePoweredByFlag: {
      value: "NO",
      isValid: true,
      isChanged: false,
    }, //value in YES/NO
    removeUnsubscribeFlag: { value: "NO", isValid: true, isChanged: false }, //value in YES?NO
    calenderDescription: {
      value: "Hi {first-name},\n Following are the details:",
      isValid: true,
      errMsg: "",
      isChanged: false,
    },
    timeZone: {
      value: { ID: "", location: "" },
      isValid: true,
      errMsg: "",
      isChanged: false,
    },
    emailSubject: {
      value: "Event Invitation for {first-name} {last-name}!",
      isValid: true,
      errMsg: "",
      isChanged: false,
    },
    startDate: {
      value: "",
      isValid: true,
      errMsg: "",
      isChanged: false,
    },
    endDate: {
      value: "",
      isValid: true,
      errMsg: "",
      isChanged: false,
    },
    senderEmail: {
      value: { ID: 0, Title: "" },
      isValid: true,
      errMsg: "",
      isChanged: false,
    },
    eventType: {
      value: { value: "", label: "" },
      isValid: true,
      errMsg: "",
      isChanged: false,
    },
    emailBody: {
      value: `<p>Hi {first-name},</p><p><br></p><p>You are cordially invited to the upcoming event.</p><p></p><p>Following are the details:</p><br><p>Thank You!</p><p><br></p><p>With Best Regards,</p><p><br></p><p>----------------------------</p><p id="footerLink">{unsubscribe-link} | {sent-via-bulkcalendar-link}</p>`,
      isValid: true,
      errMsg: "",
      isChanged: false,
    },
    eventTypeText: { value: "", isValid: true, errMsg: "", isChanged: false },
    organizerName: {
      value: organizerName,
      isValid: true,
      errMsg: "",
      isChanged: false,
    },
    organizerEmail: { value: "", isValid: true, errMsg: "", isChanged: false },
  });
  // const [textInputsFocus, setTextInputsFocus] = useState({
  //   isAnyFocus: false,
  //   focusFiedName: "",
  // });

  const [isFormValid, setIsFormValid] = useState(false);
  const [modal, setModal] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [copiedText, setCopiedText] = useState("");
  const [showGuide, setShowGuide] = React.useState(false);
  const [showLoaderOnClick, setShowLoaderOnClick] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [campaignCode, setCampaignCode] = useState(null);
  const [editLocation, setEditLocation] = useState(false);
  const [dateForDatePicker, setDateForDatePicker] = useState({
    startDate: "",
    endDate: "",
  });
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = React.useState(false);
  //state for add new email config modal
  const [addConfigModal, setAddConfigModal] = useState({
    showAddConfig: false,
    isConfigAdded: false,
  });
  const [hasSavedDataFrmLocal, setHasSavedDataFrmLocal] = useState(false);
  //a state taken to show loader while getting the data of plan list on the fly to make user toggle the sent via bulkCalendar flag.
  const [showLoaderPlanList, setShowLoaderPlanList] = useState(false);
  //ref taken for making the email config select focus
  const emailInputRef = useRef(null);
  //ref taken on for div wrapping react-select email config for scroll into view
  const emailConfigRef = useRef(null);

  const maxStartDate = new Date().setMonth(new Date().getMonth() + 3);
  const minStartDate = new Date(new Date().setHours(new Date().getHours() + 1));
  const minDateForEndDate = dateForDatePicker.startDate
    ? dateForDatePicker.startDate
    : new Date(new Date().setHours(new Date().getHours() + 1));
  const [maxDateForEndDate, setMaxDateForEndDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 3))
  );
  const timeZoneList = useSelector((state) => state.Profile.timeZone);
  const emailConfigurationList = useSelector(
    (state) => state.EmailConfigReducers.emailConfigList
  );
  // to add new as starting option in emailConfig dropdown list
  const emailConfigListWithNew = [
    ...(role === "TENANT_ADMIN"
      ? [{ ID: -1, Title: "Add New", isSpecial: true }]
      : []), //a new option added to show Add New
    ...(emailConfigurationList?.EmailConfigurations || []),
  ];

  const campaignDetails = useSelector(
    (state) => state.createCampaign.campaignDetails
  );
  const notificationAlertRef = React.useRef(null);
  const dispatch = useDispatch();
  const countries = [];
  const location = useLocation();
  const navigate = useNavigate();

  const dynamicParameters = [
    "{first-name}",
    "{last-name}",
    "{attendee-email}",
    "{email-subject}",
    "{event-start-datetime}",
    "{event-end-datetime}",
    "{event-meet-url}",
    "{event-location}",
    "{field1}",
    "{field2}",
    "{field3}",
    "{field4}",
    "{field5}",
    "{unsubscribe-link}",
  ];

  //for customizing the appearance of the React Select to match other input fields
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.className?.includes("is-invalid")
        ? "red"
        : inputBorderColor,
      height: "2.8rem",
      fontSize: "0.85rem",
    }),
    //for the add new option styling in email config list drop down
    option: (provided, state) => ({
      ...provided,
      color: state.data.isSpecial ? "blue" : provided.color,
      fontWeight: state.data.isSpecial ? "500" : provided.fontWeight,
      cursor: state.data.isSpecial ? "pointer" : provided.cursor,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: inputTextColor,
    }),
    menuList: (provided) => ({
      ...provided,
      fontSize: "0.8rem",
    }),
  };
  const [usedDynamicParams, setUsedDynamicParams] = useState({
    email: true,
    firstName: false,
    lastName: false,
    field1: false,
    field2: false,
    field3: false,
    field4: false,
    field5: false,
  });

  useEffect(() => {
    // Define the dynamic placeholders that will be searched within the field values.
    const dynamicPlaceholders = {
      email: "{attendee-email}",
      firstName: "{first-name}",
      lastName: "{last-name}",
      field1: "{field1}",
      field2: "{field2}",
      field3: "{field3}",
      field4: "{field4}",
      field5: "{field5}",
    };

    // Initialize all values in updatedUsedDynamicParams to false, except for email which remains true.
    const updatedUsedDynamicParams = Object.fromEntries(
      Object.keys(dynamicPlaceholders).map((key) => [key, key === "email"])
    );

    // Filter createCampaignFields to only include the keys present in checkValuesFor.
    const fieldsToCheck = [
      "calendarTitle",
      "calenderDescription",
      "emailSubject",
      "emailBody",
    ];

    // Loop over all the relevant fields in createCampaignFields.
    fieldsToCheck.forEach((field) => {
      const { value } = createCampaignFields[field] || {};
      let fieldValue = value;

      // Convert the value to a string if it's an object and not null.
      if (typeof fieldValue === "object" && fieldValue !== null) {
        fieldValue = JSON.stringify(fieldValue);
      }

      // Check if the field value is a string and contains any of the dynamic placeholders.
      if (typeof fieldValue === "string") {
        Object.entries(dynamicPlaceholders).forEach(([key, placeholder]) => {
          if (fieldValue.includes(placeholder)) {
            // Set the corresponding key in updatedUsedDynamicParams to true if placeholder is found.
            updatedUsedDynamicParams[key] = true;
          }
        });
      }
    });

    // Update the state with the new updatedUsedDynamicParams.
    setUsedDynamicParams(updatedUsedDynamicParams);
  }, [
    createCampaignFields.calendarTitle,
    createCampaignFields.calenderDescription,
    createCampaignFields.emailBody,
    createCampaignFields.emailSubject,
  ]); // The effect runs whenever createCampaignFields changes.

  //set email config data to state if it's only one
  // set email config data to state which is by default primary email config
  useEffect(() => {
    if (
      emailConfigurationList?.totalRecords > 0 &&
      !campaignCode &&
      !addConfigModal.isConfigAdded
    ) {
      let emailConfig = emailConfigurationList?.EmailConfigurations.find(
        (email) => email.isDefault === "YES"
      );
      // console.log("emailConfig", emailConfig);
      if (emailConfig) {
        setCreateCampaignFields((prevState) => {
          return {
            ...prevState,
            senderEmail: {
              value: emailConfig,
              isValid: true,
              errMsg: false,
              isChanged: false,
            },
            organizerEmail: {
              value: emailConfig.senderEmail,
              isValid: true,
              errMsg: "",
              isChanged: false,
            },
          };
        });
      }
      //when new config is added to make it selected in the field this is done
    } else if (addConfigModal.isConfigAdded) {
      setCreateCampaignFields((prevState) => {
        return {
          ...prevState,
          senderEmail: {
            value: emailConfigurationList?.EmailConfigurations[0],
            isValid: true,
            errMsg: false,
            isChanged: true,
          },
          organizerEmail: {
            value: emailConfigurationList?.EmailConfigurations[0].senderEmail,
            isValid: true,
            errMsg: "",
            isChanged: true,
          },
        };
      });
      //making the flag false after its use done
      setAddConfigModal((prevState) => {
        return {
          ...prevState,
          isConfigAdded: false,
        };
      });
    }
  }, [emailConfigurationList]);

  //call to get time zone list and email config
  useEffect(() => {
    getTimeZoneList(dispatch);
    listEmailConfig(dispatch, 1);

    // return () => {
    //   clearCampaignDetailsFrmStore(dispatch);
    // };
  }, []);

  useEffect(() => {
    const startDate = dateForDatePicker.startDate;
    const endDate = dateForDatePicker.endDate;
    if (startDate && endDate) {
      const durationInMs = new Date(endDate) - new Date(startDate);
      // Convert milliseconds to days, hours, and minutes
      const days = Math.floor(durationInMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (durationInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (durationInMs % (1000 * 60 * 60)) / (1000 * 60)
      );
      // Format the result as "X days, Y hours, Z minutes"
      const humanReadableDuration = {
        day: days,
        hour: hours,
        minutes: minutes,
      };
      setDuration(humanReadableDuration);
    }
  }, [dateForDatePicker]);

  useEffect(() => {
    if (campaignCode) getCampaignDetails(dispatch, campaignCode);
  }, [campaignCode]);

  useEffect(() => {
    //not of hasSavedDataFrmLocal done to avaoid setting the state once it is set from local storage
    if (campaignDetails && campaignCode && !hasSavedDataFrmLocal) {
      // Set max date limit for end date in edit mode
      const maxDate = new Date(campaignDetails.startAt);
      maxDate.setDate(maxDate.getDate() + 3);
      setMaxDateForEndDate(maxDate);
      // Set data from the API to the state object

      setCreateCampaignFields((prevState) => ({
        ...prevState,
        campaignName: {
          value: campaignDetails.campaignName,
          isValid: true,
          errMsg: "",
        },
        calendarTitle: {
          value: campaignDetails.calendarTitle,
          isValid: true,
          errMsg: "",
        },
        bookCalendar: {
          value: campaignDetails?.bookCalendar,
          isValid: true,
        },
        removePoweredByFlag: {
          value: campaignDetails.emailBody.includes(
            "{sent-via-bulkcalendar-link}"
          )
            ? "NO"
            : "YES",
          isValid: true,
        },
        removeUnsubscribeFlag: {
          value: campaignDetails.emailBody.includes("{unsubscribe-link}")
            ? "NO"
            : "YES",
          isValid: true,
        }, // here when the email body have the placeholder based on that the state is set
        calenderDescription: {
          value: campaignDetails.calenderDescription,
          isValid: true,
          errMsg: "",
        },
        timeZone: {
          value: campaignDetails.timeZone,
          isValid: true,
          errMsg: "",
          isChanged: true,
        },
        emailSubject: {
          value: campaignDetails.emailSubject,
          isValid: true,
          errMsg: "",
        },
        startDate: {
          value: campaignDetails.startAt, // Ensure the correct format for dates
          isValid: true,
          errMsg: "",
          isChanged: true,
        },
        senderEmail: {
          value: {
            ID: campaignDetails.emailConfiguration?.ID,
            Title: campaignDetails.emailConfiguration?.title,
          },
          isValid: true,
          errMsg: "",
          isChanged: true,
        },
        endDate: {
          value: campaignDetails.endAt, // Ensure the correct format for dates
          isValid: true,
          errMsg: "",
          isChanged: true,
        },
        eventType: {
          value: {
            value: campaignDetails.eventType,
            label: "",
          },
          isValid: true,
          errMsg: "",
        },
        eventTypeText: {
          value:
            campaignDetails.eventData.locationName ||
            campaignDetails.eventData.onlineEventUrl,
          isValid: true,
          errMsg: "",
        },
        emailBody: {
          value: campaignDetails.emailBody,
          isValid: true,
          errMsg: "",
        },
        organizerEmail: {
          value: campaignDetails.organizerEmail,
          isValid: true,
          isChanged: false,
          errMsg: "",
        },
        organizerName: {
          value: campaignDetails.organizerName,
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
      }));
      setDateForDatePicker({
        startDate: new Date(campaignDetails.startAt),
        endDate: new Date(campaignDetails.endAt),
      });
    } else if (!campaignCode && !hasSavedDataFrmLocal) {
      getProfileDetail(dispatch);
      setCreateCampaignFields((prevState) => ({
        ...prevState,
        campaignName: {
          value: "",
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
        calendarTitle: {
          value: "",
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
        calenderDescription: {
          value: "Hi {first-name},\nFollowing are the details:",
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
        timeZone: {
          value: { ID: "", location: "" },
          isValid: true,
          errMsg: "",
          isChanged: true,
        },
        emailSubject: {
          value: "Event Invitation for {first-name} {last-name}!",
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
        startDate: {
          value: "",
          isValid: true,
          errMsg: "",
          isChanged: true,
        },
        endDate: {
          value: "",
          isValid: true,
          errMsg: "",
          isChanged: true,
        },
        senderEmail: {
          value: { ID: 0, Title: "" },
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
        eventType: {
          value: { value: "OFFLINE", label: "Offline" },
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
        emailBody: {
          value: `<p>Hi {first-name},</p><p><br></p><p>You are cordially invited to the upcoming event.</p><p></p><p>Following are the details:</p><br><p>Thank You!</p><p><br></p><p>With Best Regards,</p><p><br></p><p>----------------------------</p><p id="footerLink">{unsubscribe-link} | {sent-via-bulkcalendar-link}</p>`,
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
        eventTypeText: {
          value: "",
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
      }));
      setDateForDatePicker({
        startDate: "",
        endDate: "",
      });
    }
  }, [campaignDetails, hasSavedDataFrmLocal]);

  useEffect(() => {
    // !campaignCode condition checked to make the timezone set only when it create mode not in edit mode
    if (
      !campaignCode &&
      profileDetails &&
      profileDetails.tenantResponse?.timeZoneResponse &&
      Object.keys(profileDetails.tenantResponse.timeZoneResponse).length > 0
    ) {
      setCreateCampaignFields((prevState) => ({
        ...prevState,
        timeZone: {
          value: profileDetails.tenantResponse.timeZoneResponse,
          isValid: true,
          errMsg: "",
        },
      }));
    }
  }, [profileDetails]);

  useEffect(() => {
    if (location.state?.campaignCode) {
      setCampaignCode(location.state?.campaignCode);
    } else {
      const existingCampaignState = JSON.parse(
        localStorage.getItem("savedCampaignState")
      );
      if (existingCampaignState) {
        const { campaignCode, ...rest } = existingCampaignState;
        setCreateCampaignFields(rest);
        if (
          existingCampaignState.startDate.value &&
          existingCampaignState.endDate.value
        ) {
          setDateForDatePicker({
            startDate: new Date(existingCampaignState.startDate.value),
            endDate: new Date(existingCampaignState.endDate.value),
          });
        }
        setHasSavedDataFrmLocal(true); //a flag taken to know that currently form field is showing the saved data from local storage
        //in case of edit
        if (campaignCode) {
          setCampaignCode(campaignCode);
        }
        localStorage.removeItem("savedCampaignState");
      }
    }
  }, []);

  //handle error for add email configuration
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get("error");
    const success = searchParams.get("success");

    if (success) {
      listEmailConfig(dispatch, 1);
      //the parameter true passed to make the state in parent component isAdded true
      setAddConfigModal((prevState) => {
        return {
          ...prevState,
          isConfigAdded: true,
        };
      });
      emailInputRef?.current?.focus();
      setTimeout(() => {
        emailConfigRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center", // Ensures the element is aligned to the center vertically
          inline: "nearest", // Aligns horizontally, useful for horizontal scroll
        });
      }, 500);
      notify("success", {
        message: "Email configuration added successfully",
        subMessage: "",
      });
    } else if (error) {
      notify("error", {
        message: "You have not provided sufficient permission.",
        subMessage: "For Adding Email Configuration",
      });
    }
    // Remove search params after handling
    const newURL = window.location.pathname; // Retains only the path without query params
    window.history.replaceState(null, "", newURL); // Update URL without reloading
  }, []);

  //validation check for valid form
  useEffect(() => {
    if (checkObjectValueValidAndNonEmpty(createCampaignFields)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [createCampaignFields]);

  //to check if all fields of an object is filled and isValid
  const checkObjectValueValidAndNonEmpty = (obj) => {
    let isValid = false;
    for (const key in obj) {
      if (
        key === "emailBody" &&
        obj[key].value.trim().length > 0 &&
        obj[key].value !== "<p><br></p>" &&
        obj[key].isValid
      ) {
        isValid = true;
      } else if (
        typeof obj[key].value === "string" &&
        obj[key].value.trim().length > 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else if (
        typeof obj[key].value === "object" &&
        Object.values(obj[key].value)[0] !== "" &&
        Object.values(obj[key].value)[0] !== 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else {
        return false;
      }
    }
    return isValid;
  };

  //on change of place setting state
  const handlePlaceChange = (e) => {
    const place = e.target.valueInternal;

    if (place && e.target.value?.formattedAddress) {
      const placeId = place.id;
      // const lat = place.Eg.lat;
      // const lng = place.Eg.lng;

      // Construct the Google Maps URL using place ID
      const url = `https://www.google.com/maps/place/?q=place_id:${placeId}`;
      setFormattedAddress(e.target.value?.formattedAddress);
      setEditLocation(false);
      setCreateCampaignFields((prevState) => {
        return {
          ...prevState,
          eventTypeText: {
            value: e.target.value?.formattedAddress,
            locationUrl: url,
            placeId: placeId,
            isValid: true,
            errMsg: "",
            isChanged: true,
          },
        };
      });
    } else {
      setFormattedAddress("");
      setCreateCampaignFields((prevState) => {
        return {
          ...prevState,
          eventTypeText: {
            value: "",
            isValid: false,
            errMsg: "Please select a valid location",
          },
        };
      });
    }
  };

  //on change of the input fields to set the values
  const handleOnchange = (name, value) => {
    if (name === "endDate" && !createCampaignFields.startDate.isValid) {
      const checkValidDate =
        new Date(createCampaignFields.startDate.value) < new Date(value);
      setCreateCampaignFields((prevState) => {
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: value,
            isValid: true,
            isChanged: true,
          },
          startDate: {
            ...prevState.startDate,
            isValid: checkValidDate,
          },
        };
      });
    } else if (name === "startDate" && !createCampaignFields.endDate.isValid) {
      const checkValidDate =
        new Date(createCampaignFields.endDate.value) > new Date(value);
      setCreateCampaignFields((prevState) => {
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: value,
            isValid: true,
            isChanged: true,
          },
          endDate: {
            ...prevState.endDate,
            isValid: checkValidDate,
          },
        };
      });
    } else {
      setCreateCampaignFields((prevState) => {
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: value,
            isValid: true,
            isChanged: true,
          },
        };
      });
    }
  };

  //onFocus of Input (majorly done for selecting dynamic parameters)
  // const handleOnFocus = (name) => {
  //   setTextInputsFocus((prevState) => {
  //     return { ...prevState, focusFiedName: name, isAnyFocus: true };
  //   });
  // };

  function isUrlValid(userInput) {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  }

  //onBlur of Input to handle validation and setFocus false for selecting the dynamic parameter
  const handleOnBlur = (forParameters, name, value, typeOfEvent) => {
    let valid = true;
    let errorMessage = "";
    switch (name) {
      case "campaignName":
        const campaignNameLength = value.trim().length;
        if (campaignNameLength === 0) {
          valid = false;
          errorMessage = "Please enter a Calendar Name.";
        } else if (campaignNameLength < 5) {
          valid = false;
          errorMessage =
            "Please enter a Calendar Name containing at least 5 characters.";
        } else if (
          dynamicParameters.some((placeholder) => value.includes(placeholder))
        ) {
          valid = false;
          errorMessage =
            "Calendar Name cannot contain placeholders like {first-name}, {last-name}, or others.";
        }
        break;
      case "calendarTitle":
        const calendarTitleLength = value.trim().length;
        if (calendarTitleLength === 0) {
          valid = false;
          errorMessage = "Please enter a Calendar Title.";
        } else if (calendarTitleLength < 5) {
          valid = false;
          errorMessage =
            "Please enter a Calendar Title containing at least 5 characters.";
        }
        break;
      case "calenderDescription":
        const descriptionLength = value.trim().length;
        if (descriptionLength === 0) {
          valid = false;
          errorMessage = "Please enter Calendar Description.";
        } else if (descriptionLength < 10) {
          valid = false;
          errorMessage =
            "Please enter a Calendar Description containing at least 10 characters.";
        }
        break;
      case "timeZone":
        const timeZoneLength = value.trim().length;
        if (timeZoneLength === 0) {
          valid = false;
          errorMessage = "Please Select a Time Zone.";
        }
        break;
      case "senderEmail":
        const senderEmailLength = value.trim().length;
        if (senderEmailLength === 0 && !addConfigModal.showAddConfig) {
          valid = false;
          errorMessage = "Please Select a Email configuration.";
        }
        break;
      case "eventType":
        const eventTypeLength = value.trim().length;
        if (eventTypeLength === 0) {
          valid = false;
          errorMessage = "Please Select a Event Type.";
        }
        break;
      case "eventTypeText":
        const eventTypeTextLength = value.trim().length;
        if (typeOfEvent === "ONLINE") {
          if (eventTypeTextLength === 0) {
            valid = false;
            errorMessage = "Please enter URL";
          } else if (!isUrlValid(value)) {
            valid = false;
            errorMessage = "Please Add Valid URL";
          }
        } else {
          if (eventTypeTextLength === 0) {
            valid = false;
            errorMessage = "Please select a valid location";
          }
        }
        break;
      case "emailSubject":
        const subjectLength = value.trim().length;
        if (subjectLength === 0) {
          valid = false;
          errorMessage = "Please enter an email subject.";
        } else if (subjectLength < 10) {
          valid = false;
          errorMessage =
            "Please enter an email subject containing at least 10 characters.";
        } else if (value.includes("{email-subject}")) {
          valid = false;
          errorMessage =
            "You can't use '{email-subject}' as part of the email subject.";
        }
        break;
      case "emailBody":
        const emailBodyLength = value.trim().length;
        if (emailBodyLength === 0 || value === "<p><br></p>") {
          valid = false;
          errorMessage = "Please enter email body.";
        }
        if (emailBodyLength < 10) {
          valid = false;
          errorMessage =
            "Please enter a email body containing at least 30 characters.";
        }
        break;
      case "eventTypeText":
        if (value.trim().length === 0) {
          valid = false;
          errorMessage = "Please enter a valid location";
        }
        break;
      case "startDate":
        const startDate = new Date(value);
        const endDate = new Date(createCampaignFields.endDate.value);
        if (value === "") {
          valid = false;
          errorMessage = "Please select a start date.";
        } else if (startDate <= new Date()) {
          valid = false;
          errorMessage = "Please select a future date time.";
        } else if (startDate >= endDate) {
          valid = false;
          errorMessage = "Start date must be before end date.";
        }
        break;
      case "endDate":
        const eDate = new Date(value);
        const sDate = new Date(createCampaignFields.startDate.value);
        if (value === "") {
          valid = false;
          errorMessage = "Please select an end date.";
        } else if (eDate <= new Date()) {
          valid = false;
          errorMessage = "Please select a future date time.";
        } else if (eDate <= sDate) {
          valid = false;
          errorMessage = "End date must be after start date.";
        }
        break;
      case "organizerName":
        const organizerName = value.trim().length;
        if (organizerName === 0) {
          valid = false;
          errorMessage = "Please enter a organizer name.";
        }
        break;
      case "organizerEmail":
        const organizerEmail = value.trim();
        if (organizerEmail.length === 0) {
          valid = false;
          errorMessage = "Please enter an organizer email.";
        } else {
          // Regular expression for validating email format
          if (!emailRegex.test(organizerEmail)) {
            valid = false;
            errorMessage = "Please enter a valid email address.";
          }
        }
        break;
      default:
        break;
    }
    setCreateCampaignFields((prevState) => {
      return {
        ...prevState,
        [name]: {
          ...prevState[name],
          isValid: valid,
          errMsg: errorMessage,
        },
      };
    });

    // if (forParameters) {
    //   setTimeout(() => {
    //     if (textInputsFocus.isAnyFocus) {
    //       setTextInputsFocus((prevState) => {
    //         return { ...prevState, focusFiedName: "", isAnyFocus: false };
    //       });
    //     }
    //   }, 200);
    // }
    // setTextInputsFocus((prevState) => {
    //   return { ...prevState, focusFiedName: "", isAnyFocus: false };
    // });
  };

  const handleNext = () => {
    navigate("/a/campaigns/step-2", {
      state: { campaignCode: campaignCode },
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handlePreview = () => {
    setModal(true);
  };

  const notify = (type, msgObj) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {msgObj.message}
          </span>
          <span data-notify="message">{msgObj.subMessage}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  const handleCreateCampaignStep1 = async () => {
    const bodyData = {
      emailConfigurationID: createCampaignFields.senderEmail.value.ID,
      timeZoneID: createCampaignFields.timeZone.value.ID,
      campaignName: createCampaignFields.campaignName.value,
      calendarTitle: createCampaignFields.calendarTitle.value,
      bookCalendar: createCampaignFields.bookCalendar.value,
      calenderDescription: createCampaignFields.calenderDescription.value,
      emailSubject: createCampaignFields.emailSubject.value,
      emailBody: createCampaignFields.emailBody.value,
      startAt: createCampaignFields.startDate.value,
      endAt: createCampaignFields.endDate.value,
      organizerName: createCampaignFields.organizerName.value,
      organizerEmail: createCampaignFields.organizerEmail.value,
      eventType: createCampaignFields.eventType.value.value,
      eventData: {
        locationUrl:
          createCampaignFields.eventType.value.value === "OFFLINE"
            ? createCampaignFields.eventTypeText?.locationUrl
            : "",
        placeID:
          createCampaignFields.eventType.value.value === "OFFLINE"
            ? createCampaignFields.eventTypeText?.placeId
            : "",
        locationName:
          createCampaignFields.eventType.value.value === "OFFLINE"
            ? createCampaignFields.eventTypeText.value
            : "",
        onlineEventUrl:
          createCampaignFields.eventType.value.value === "ONLINE"
            ? createCampaignFields.eventTypeText.value
            : "",
      },
      organizerName: createCampaignFields.organizerName.value,
      organizerEmail: createCampaignFields.organizerEmail.value,
      placeHolders: usedDynamicParams,
    };
    const res = await createCampaignStep1(bodyData);
    if (res.code === 200) {
      notify("success", {
        message: "Calendar step1 created successfully!",
        subMessage: "",
      });
      handlePreview();
      setCampaignCode(res.data);
    } else if (res.code === 400) {
      notify("error", {
        message: Array.isArray(res.message)
          ? res.message[0].Msg
          : res.message
          ? res.message
          : "Something went wrong",
        subMessage: "Please check the details you entered.",
      });
    } else {
      notify("error", {
        message: res.message ? res.message : "Something went wrong",
        subMessage: "Please check the details you entered.",
      });
    }
    setShowLoaderOnClick(false);
  };

  const getBodyData = (createCampaignFields) => {
    const bodyData = {};

    // Helper function to add a value if `isChanged` is true
    const addIfChanged = (fieldKey, value, objectKey = fieldKey) => {
      if (createCampaignFields[fieldKey]?.isChanged) {
        bodyData[objectKey] = value;
      }
    };

    // Add values if `isChanged` is true
    addIfChanged(
      "senderEmail",
      createCampaignFields.senderEmail.value.ID,
      "emailConfigurationID"
    );
    addIfChanged(
      "timeZone",
      createCampaignFields.timeZone.value.ID,
      "timeZoneID"
    );
    addIfChanged("campaignName", createCampaignFields.campaignName.value);
    addIfChanged("calendarTitle", createCampaignFields.calendarTitle.value);
    addIfChanged("bookCalendar", createCampaignFields.bookCalendar.value);
    addIfChanged(
      "calenderDescription",
      createCampaignFields.calenderDescription.value
    );
    addIfChanged("emailSubject", createCampaignFields.emailSubject.value);
    addIfChanged("emailBody", createCampaignFields.emailBody.value);
    addIfChanged("organizerName", createCampaignFields.organizerName.value);
    addIfChanged("organizerEmail", createCampaignFields.organizerEmail.value);

    // Handle startDate and endDate changes
    if (createCampaignFields.timeZone?.isChanged) {
      bodyData.startAt = formatDateTime(createCampaignFields.startDate.value);
      bodyData.endAt = formatDateTime(createCampaignFields.endDate.value);
    } else if (createCampaignFields.startDate?.isChanged) {
      bodyData.startAt = createCampaignFields.startDate.value;
      bodyData.endAt = formatDateTime(createCampaignFields.endDate.value);
    } else if (createCampaignFields.endDate?.isChanged) {
      bodyData.endAt = createCampaignFields.endDate.value;
      bodyData.startAt = formatDateTime(createCampaignFields.startDate.value);
    }

    addIfChanged("eventType", createCampaignFields.eventType.value.value);
    // For eventData
    if (
      createCampaignFields.eventType.isChanged ||
      createCampaignFields.eventTypeText.isChanged
    ) {
      bodyData.eventData = {};
      if (createCampaignFields.eventType.value.value === "OFFLINE") {
        if (createCampaignFields.eventTypeText?.isChanged) {
          bodyData.eventData.locationUrl =
            createCampaignFields.eventTypeText?.locationUrl || "";
          bodyData.eventData.placeID =
            createCampaignFields.eventTypeText?.placeId || "";
          bodyData.eventData.locationName =
            createCampaignFields.eventTypeText?.value || "";
        }
      }

      if (createCampaignFields.eventType.value.value === "ONLINE") {
        if (createCampaignFields.eventTypeText?.isChanged) {
          bodyData.eventData.onlineEventUrl =
            createCampaignFields.eventTypeText?.value || "";
        }
      }
    }

    return bodyData;
  };

  const updateCampaignData = async (params) => {
    let bodyData = getBodyData(createCampaignFields);
    if (params && params.markAttendeeUnsend) {
      bodyData = {
        ...bodyData,
        markAttendeeUnsend: true,
      };
    }

    //To send the placeholders everytime the campaign is get updated
    bodyData = { ...bodyData, placeHolders: usedDynamicParams };

    const res = await updateCampaign(campaignCode, bodyData);
    if (res.code === 200) {
      notify("success", {
        message: "Calendar step1 updated successfully!",
        subMessage: "",
      });
      getCampaignDetails(dispatch, campaignCode);
      handlePreview();
    } else if (res.code === 400) {
      notify("error", {
        message: Array.isArray(res.message)
          ? res.message[0].Msg
          : res.message
          ? res.message
          : "Something went wrong",
        subMessage: "Please check the details you entered.",
      });
    } else {
      notify("error", {
        message: res.message ? res.message : "Something went wrong",
        subMessage: "Please check the details you entered.",
      });
    }
    setShowLoaderOnClick(false);
  };

  const handleCampaignUpdate = () => {
    //condition checked that if calendar is in progress so to restrict further action
    if (
      campaignDetails &&
      campaignDetails.status === CAMPAIGN_STATUS_IN_PROGRESS
    ) {
      setShowLoaderOnClick(false);
      return showInProgressAlert();
    }

    if (campaignDetails.status === "COMPLETED") {
      Swal.fire({
        title: "Do you want to update?",
        html: `<span style="display: block; text-align: justify; font-size: 16px; line-height: 1.5;">
           You are making changes to a completed calendar. 
           Would you also like to reset the status of "Sent" attendees to "Pending" 
           so that invites can be sent again?
         </span>`,
        icon: "info",
        showCancelButton: true, // This is for "No, just update the campaign" button
        confirmButtonColor: "#0DCAF0",
        cancelButtonColor: "#adb5bd",
        confirmButtonText: "Yes, change it!",
        cancelButtonText: "No, just update the calendar",
        showCloseButton: true, // Adds a close icon to the top-right
        allowOutsideClick: false, // Prevent alert from closing on outside click
        customClass: {
          closeButton: "custom-close-button", // Apply custom class to the close button
        },
      }).then((result) => {
        if (result.isConfirmed) {
          updateCampaignData({ markAttendeeUnsend: true }); // User confirmed action
        } else if (result.isDismissed && result.dismiss !== "close") {
          updateCampaignData(); // Simple update on cancel button click
        }
      });
      setShowLoaderOnClick(false);
    } else {
      updateCampaignData();
    }
  };

  const handleSelectChange = (selected, e) => {
    let emalBodyContent = createCampaignFields.emailBody.value;
    let emailSubjectText = createCampaignFields.emailSubject.value;
    let calendarDescText = createCampaignFields.calenderDescription.value;
    if (e.name === "eventType") {
      // this is done to make the event type placeholder change if event type chnages
      // such as when event type is online and the placholder used inside body is
      // still {event-location} so to change that the below thing  is done and vice versa
      // this part is commented because as of now the calendar parameters are removed.
      // if (
      //   selected.value === "ONLINE" &&
      //   (emalBodyContent.includes("{event-location}") ||
      //     emailSubjectText.includes("{event-location}") ||
      //     calendarDescText.includes("{event-location}"))
      // ) {
      //   emalBodyContent = emalBodyContent.replace(
      //     "{event-location}",
      //     "{event-meet-url}"
      //   );
      //   emailSubjectText = emailSubjectText.replace(
      //     "{event-location}",
      //     "{event-meet-url}"
      //   );
      //   calendarDescText = calendarDescText.replace(
      //     "{event-location}",
      //     "{event-meet-url}"
      //   );
      // } else if (
      //   selected.value === "OFFLINE" &&
      //   (emalBodyContent.includes("{event-meet-url}") ||
      //     emailSubjectText.includes("{event-meet-url}") ||
      //     calendarDescText.includes("{event-meet-url}"))
      // ) {
      //   emalBodyContent = emalBodyContent.replace(
      //     "{event-meet-url}",
      //     "{event-location}"
      //   );
      //   emailSubjectText = emailSubjectText.replace(
      //     "{event-meet-url}",
      //     "{event-location}"
      //   );
      //   calendarDescText = calendarDescText.replace(
      //     "{event-meet-url}",
      //     "{event-location}"
      //   );
      // }
      //on change of event type making the dependenty event text empty
      setCreateCampaignFields((prevState) => {
        return {
          ...prevState,
          [e.name]: {
            value: selected,
            isValid: true,
            errMsg: false,
            isChanged: true,
          },
          eventTypeText: {
            value: "",
            isValid: true,
            errMsg: "",
            isChanged: true,
          },
          emailBody: {
            ...prevState.emailBody,
            value: emalBodyContent,
          },
          emailSubject: {
            ...prevState.emailSubject,
            value: emailSubjectText,
          },
          calenderDescription: {
            ...prevState.calenderDescription,
            value: calendarDescText,
          },
        };
      });
    } else if (e.name === "senderEmail") {
      //for add new modal
      if (selected.ID === -1) {
        setAddConfigModal((prevState) => {
          return { ...prevState, showAddConfig: true };
        });
      } else {
        setCreateCampaignFields((prevState) => {
          return {
            ...prevState,
            [e.name]: {
              value: selected,
              isValid: true,
              errMsg: false,
              isChanged: true,
            },
            organizerEmail: {
              value: selected.senderEmail,
              isValid: true,
              errMsg: "",
              isChanged: true,
            },
          };
        });
      }
    } else {
      setCreateCampaignFields((prevState) => {
        return {
          ...prevState,
          [e.name]: {
            value: selected,
            isValid: true,
            errMsg: false,
            isChanged: true,
          },
        };
      });
    }
  };

  // Function to calculate the minimum date and time
  // const getMinDateTime = () => {
  //   const now = new Date();
  //   now.setHours(now.getHours() + 1); // Add 1 hour
  //   return formatDateTime(now);
  // };

  // Function to calculate the maximum date (3 months from now)
  // const getMaxDateTime = () => {
  //   const now = new Date();
  //   now.setMonth(now.getMonth() + 3); // Add 3 months
  //   return formatDateTime(now);
  // };

  //on click of copy icon or text
  const handleCopyClick = async (text) => {
    try {
      await window.navigator.clipboard.writeText(text);
      setCopiedText(text);
      setTimeout(() => {
        setCopiedText("");
      }, 1000);
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
      alert("Copy to clipboard failed.");
    }
  };

  //handle modal of intruction video
  const handleToggle = () => {
    setShowGuide(!showGuide);
  };

  // const handleClickDynamicParam = (item) => {
  //   if (
  //     textInputsFocus.isAnyFocus &&
  //     (item.value !== "{email-subject}" ||
  //       (item.value === "{email-subject}" &&
  //         textInputsFocus.focusFiedName !== "subject"))
  //   ) {
  //     setCreateCampaignFields((prevState) => {
  //       return {
  //         ...prevState,
  //         [textInputsFocus.focusFiedName]: {
  //           value:
  //             prevState[textInputsFocus.focusFiedName].value + ` ${item.value}`,
  //           isValid: true,
  //           isChanged: true,
  //         },
  //       };
  //     });
  //   }
  // };

  const handleEditLocation = () => {
    setCreateCampaignFields((prevState) => {
      return {
        ...prevState,
        eventTypeText: {
          value: "",
          locationUrl: "",
          placeId: 0,
          isValid: true,
          errMsg: "",
          isChanged: false,
        },
      };
    });
    setEditLocation(true);
  };

  // const handleClickOnEventType = () => {
  //   if (textInputsFocus.isAnyFocus) {
  //     setCreateCampaignFields((prevState) => {
  //       return {
  //         ...prevState,
  //         [textInputsFocus.focusFiedName]: {
  //           value:
  //             prevState[textInputsFocus.focusFiedName].value +
  //             `${
  //               createCampaignFields.eventType.value === "ONLINE"
  //                 ? `{event-meet-url}`
  //                 : `{event-location}`
  //             }`,
  //           isValid: true,
  //           isChanged: true,
  //         },
  //       };
  //     });
  //   }
  // };

  const handleDateChange = async (date, type) => {
    //Set start date in Datefordatepicker
    setDateForDatePicker((prevState) => {
      const newState = { ...prevState, [type]: date };

      // If the type is startDate, update endDate only if startDate >= current endDate
      if (type === "startDate") {
        const endDate = new Date(date);
        endDate.setHours(endDate.getHours() + 1);
        newState["endDate"] = endDate;

        //Setting max date limit for ending date in create campaign mode
        const maxDate = new Date(date);
        maxDate.setDate(maxDate.getDate() + 3);
        setMaxDateForEndDate(maxDate);
      }

      return newState;
    });
    // Format the selected date
    const formattedDate = await formatDateTime(date);
    // Set start date in gobal state
    handleOnchange(type, formattedDate);

    // If startDate was changed and endDate updated, format and handle endDate change
    if (type === "startDate") {
      const currentEndDate = dateForDatePicker.endDate;
      if (!currentEndDate || date >= currentEndDate) {
        const endDate = new Date(date);
        endDate.setHours(endDate.getHours() + 1);
        const formattedEndDate = await formatDateTime(endDate);
        handleOnchange("endDate", formattedEndDate);
      }
    }
  };

  const ResetFormFields = () => {
    setCreateCampaignFields((prevState) => ({
      ...prevState,
      campaignName: {
        value: "",
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      calendarTitle: {
        value: "",
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      bookCalendar: { value: "YES", isValid: true, isChanged: false }, //value in YES/NO
      removePoweredByFlag: { value: "NO", isValid: true, isChanged: false }, //value in YES/NO
      removeUnsubscribeFlag: { value: "NO", isValid: true, isChanged: false }, //value in YES?NO
      calenderDescription: {
        value: "Hi {first-name},\n Following are the details:",
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      timeZone: {
        value: { ID: "", location: "" },
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      emailSubject: {
        value: "Event Invitation for {first-name} {last-name}!",
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      startDate: {
        value: "",
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      endDate: {
        value: "",
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      senderEmail: {
        value: { ID: 0, Title: "" },
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      eventType: {
        value: { value: "", label: "" },
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      emailBody: {
        value: `<p>Hi {first-name},</p><p><br></p><p>You are cordially invited to the upcoming event.</p><p></p><p>Following are the details:</p><br><p>Thank You!</p><p><br></p><p>With Best Regards,</p><p><br></p><p>----------------------------</p><p id="footerLink>{unsubscribe-link} | {sent-via-bulkcalendar-link}</p>`,
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
      eventTypeText: {
        value: "",
        isValid: true,
        errMsg: "",
        isChanged: false,
      },
    }));
    setDateForDatePicker({
      startDate: "",
      endDate: "",
    });
    setDuration({
      day: 0,
      hour: 0,
      minutes: 0,
    });
  };

  // for the dropdoen options in email config to show add new with add icon
  const CustomOption = (props) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        {data?.isSpecial && (
          <i className="fa fa-add" style={{ marginRight: 10 }} />
        )}
        {data.Title}
      </components.Option>
    );
  };

  //to close the Add Config modal and if new record is added to make that as well true.
  const toggleAddConfigModal = (isAdded) => {
    setAddConfigModal({
      showAddConfig: false,
      isConfigAdded: isAdded ? true : false,
    });
  };

  //on toogle of Remove "Sent via BulkCalendar.com"
  const handleSentViaBulkChange = async (e) => {
    setShowLoaderPlanList(true);
    const planData = await getPlanListForCampaign(); //on the fly call to plan list to get plan and take decision
    if (planData && planData.tenantActivePlan) {
      if (planData.tenantActivePlan.planID === 1) {
        //plan ID 1 refers to free plan here
        setUpgradeModalIsOpen(!upgradeModalIsOpen);
      } else {
        //if plan is other then free plan then only allow to remove the flag and change
        handleOnchange(
          e.target.name,
          e.target.checked === false ? "YES" : "NO"
        );
      }
    } else {
      Swal.fire({
        title: "Something went wrong",
        text: `Try again later.`,
        timerProgressBar: true,
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    }
    setShowLoaderPlanList(false);
  };

  return (
    <>
      <Warning />
      <EmailConfigModal
        isOpen={addConfigModal.showAddConfig}
        toggleAddConfigModal={toggleAddConfigModal}
        notify={notify}
        createCampaignFields={createCampaignFields}
        campaignCode={campaignCode}
      />
      <PreviewModal
        usedDynamicParams={usedDynamicParams}
        createCampaignFields={createCampaignFields}
        modal={modal}
        toggleModal={toggle}
        handleNext={handleNext}
        notify={notify}
        campaignCode={campaignCode}
        campaignStatus={campaignDetails.status}
      />
      <InstructionModal
        show={showGuide}
        handleToggle={handleToggle}
        youtubeEmbededURL={campaignGuide}
        videoName="New to BulkCalendar? Watch This Quick Guide"
        size="lg"
      />
      {/* Modal to show when user of free plan tries to remove bulkcalendar.com link */}
      <Modal
        isOpen={upgradeModalIsOpen}
        toggle={() => setUpgradeModalIsOpen(!upgradeModalIsOpen)}
        centered
      >
        <ModalHeader
          toggle={() => setUpgradeModalIsOpen(!upgradeModalIsOpen)}
          className="pb-0"
        >
          Upgrade Plan
        </ModalHeader>
        <ModalBody>
          <p>
            Users on the Free Plan cannot change this setting. Please upgrade
            your plan to access this feature.
          </p>

          <Button
            color={"info"}
            type="submit"
            onClick={() => {
              window.open("/a/plans", "_blank"); // Opens in a new tab or window
              setUpgradeModalIsOpen(false);
            }}
            className="float-right"
          >
            <i className="fa-solid fa-arrow-up text-white mr-2"></i>Upgrade Plan
          </Button>
        </ModalBody>
      </Modal>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <SimpleHeader name="Create Campaign - Step 1" /> */}
      <Container className="mt--6" fluid>
        {emailConfigurationList?.EmailConfigurations?.length === 0 ? (
          <Row className="justify-content-center">
            <Col md={6}>
              <Card className="shadow-sm border-0 rounded-lg p-4 text-center">
                <CardBody>
                  <CardText className="mb-3 font-weight-500">
                    Set Up Your Email Configuration
                  </CardText>
                  <CardText className="mb-4 text-muted">
                    To proceed with sending bulk calendar invitations, please
                    add the email configuration.
                  </CardText>
                  <Button
                    color="info"
                    className="px-4 py-2 text-white"
                    onClick={() => navigate("/admin/email")}
                  >
                    Add Email Configuration
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col xs={12} md={4}>
                  <h3 className="mb-0">
                    {`${campaignCode !== null ? "Edit" : "Create"}`} Calendar -{" "}
                    <span style={styles.stepStyle}>Step 1/2</span>
                  </h3>
                </Col>
                <Col className="text-right" md={8}>
                  <Button
                    className="btn-icon"
                    color="error"
                    outline
                    type="button"
                    onClick={handleToggle}
                  >
                    <span className="btn-inner--icon">
                      <i className="fab fa-youtube text-red fa-lg" />
                    </span>
                    <span className="btn-inner--text">Calendar Guide</span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="7">
                  <FormGroup>
                    <label className="form-control-label">
                      Calendar Name (for internal reference)
                      <strong style={{ color: "red" }}> *</strong>
                    </label>
                    <Input
                      placeholder="Calendar Name (for internal reference)"
                      type="text"
                      name="campaignName"
                      value={createCampaignFields.campaignName.value}
                      onChange={(e) =>
                        handleOnchange(e.target.name, e.target.value)
                      }
                      onBlur={(e) =>
                        handleOnBlur(
                          false,
                          e.target.name,
                          e.target.value.trim()
                        )
                      }
                      className={
                        createCampaignFields.campaignName.isValid
                          ? ""
                          : "is-invalid"
                      }
                      maxLength={60}
                      autoComplete="off"
                    />
                    {!createCampaignFields.campaignName.isValid && (
                      <div className="invalid-feedback">
                        {createCampaignFields.campaignName.errMsg}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className="d-flex mb-3">
                    <label className="form-control-label">
                      Send auto-accepted calendar invites{" "}
                      {/* <strong style={{ color: "red" }}>*</strong>{" "} */}
                      <span>
                        <i
                          className="fa fa-info-circle"
                          id="tooltipBookCal"
                          onClick={(e) => e.preventDefault()}
                          style={styles.cusrorPointer}
                        ></i>
                        <UncontrolledTooltip delay={0} target="tooltipBookCal">
                          When this is on, the event is added to attendees'
                          calendars and set to 'Yes' (attending).
                        </UncontrolledTooltip>
                      </span>
                    </label>
                    <Label className="custom-toggle custom-toggle-info ml-3">
                      <Input
                        type="checkbox"
                        checked={
                          createCampaignFields?.bookCalendar.value === "YES"
                            ? true
                            : false
                        }
                        name="bookCalendar"
                        onChange={(e) =>
                          handleOnchange(
                            e.target.name,
                            e.target.checked === true ? "YES" : "NO"
                          )
                        }
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="NO"
                        data-label-on="YES"
                      ></span>
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label">
                      Calendar Title <strong style={{ color: "red" }}>*</strong>
                    </label>
                    <Input
                      placeholder="Calendar Title"
                      type="text"
                      name="calendarTitle"
                      value={createCampaignFields.calendarTitle.value}
                      onChange={(e) =>
                        handleOnchange(e.target.name, e.target.value)
                      }
                      onBlur={(e) =>
                        handleOnBlur(false, e.target.name, e.target.value)
                      }
                      className={
                        createCampaignFields.calendarTitle.isValid
                          ? ""
                          : "is-invalid"
                      }
                      maxLength={100}
                      autoComplete="off"
                    />
                    {!createCampaignFields.calendarTitle.isValid && (
                      <div className="invalid-feedback">
                        {createCampaignFields.calendarTitle.errMsg}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label">
                      Calendar Description{" "}
                      <strong style={{ color: "red" }}>*</strong>
                    </label>
                    <Input
                      rows="4"
                      type="textarea"
                      placeholder="Calendar Description"
                      name="calenderDescription"
                      value={createCampaignFields.calenderDescription.value}
                      onChange={(e) =>
                        handleOnchange(e.target.name, e.target.value)
                      }
                      // onFocus={(e) => handleOnFocus(e.target.name)}
                      onBlur={(e) =>
                        handleOnBlur(true, e.target.name, e.target.value)
                      }
                      className={
                        createCampaignFields.calenderDescription.isValid
                          ? ""
                          : "is-invalid"
                      }
                      maxLength={6000}
                      autoComplete="off"
                    />
                    {!createCampaignFields.calenderDescription.isValid && (
                      <div className="invalid-feedback">
                        {createCampaignFields.calenderDescription.errMsg}
                      </div>
                    )}
                  </FormGroup>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">
                          Time Zone <strong style={{ color: "red" }}>*</strong>
                        </label>
                        <Select
                          options={timeZoneList}
                          placeholder="Select Time Zone"
                          name="timeZone"
                          getOptionLabel={(options) => options.location}
                          getOptionValue={(options) => options.ID}
                          onChange={(selected, e) =>
                            handleSelectChange(selected, e)
                          }
                          onBlur={() =>
                            handleOnBlur(
                              false,
                              "timeZone",
                              createCampaignFields.timeZone.value.location
                            )
                          }
                          styles={customStyles}
                          value={timeZoneList?.find(
                            (options) =>
                              options.ID ===
                              createCampaignFields.timeZone.value.ID
                          )} // find the selected option based on value
                          isSearchable={true} // enable search functionality
                          className={
                            !createCampaignFields.timeZone.isValid
                              ? "is-invalid"
                              : null
                          }
                        />
                        {!createCampaignFields.timeZone.isValid && (
                          <small className="text-danger">
                            {createCampaignFields.timeZone.errMsg}
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="form-control-label">
                          Event Start Date{" "}
                          <strong style={{ color: "red" }}>*</strong>
                        </Label>
                        <DatePicker
                          placeholderText="Select Start Date"
                          showIcon
                          showTimeSelect
                          icon={
                            <i className="fa-solid fa-calendar-day fa-md mt-2" />
                          }
                          toggleCalendarOnIconClick
                          selected={dateForDatePicker.startDate}
                          onChange={(date) =>
                            handleDateChange(date, "startDate")
                          }
                          onCalendarClose={() =>
                            handleOnBlur(
                              false,
                              "startDate",
                              dateForDatePicker.startDate
                            )
                          }
                          onBlur={(e) =>
                            handleOnBlur(
                              false,
                              "startDate",
                              dateForDatePicker.startDate
                            )
                          }
                          timeFormat="hh:mm aa"
                          timeIntervals={15}
                          dateFormat="dd MMM yyyy, hh:mm aa"
                          minDate={minStartDate}
                          maxDate={maxStartDate}
                          className={`form-control ${
                            createCampaignFields.startDate.isValid
                              ? ""
                              : "is-invalid"
                          }`}
                          calendarContainer={(props) => {
                            // Destructure and remove unwanted props,
                            // such as showTime and showTimeSelectOnly or any others as passing to the DOM was showing warnings
                            const {
                              showTime,
                              showTimeSelectOnly,
                              ...restProps
                            } = props;

                            return (
                              <div
                                {...restProps}
                                style={styles.datePickerContainer}
                              />
                            );
                          }}
                          style={styles.datePickerContainer}
                          wrapperClassName="form-control "
                        />
                        {!createCampaignFields.startDate.isValid ? (
                          <small className="text-danger">
                            {createCampaignFields.startDate.errMsg}
                          </small>
                        ) : (
                          <small className="mb--4">
                            {(duration.day > 0 ||
                              duration.hour > 0 ||
                              duration.minutes > 0) && (
                              <>
                                Duration:&nbsp;
                                {duration.day > 0 && `${duration.day} days`}
                                {duration.day > 0 && duration.hour > 0 && ", "}
                                {duration.hour > 0 && `${duration.hour} hours`}
                                {(duration.hour > 0 || duration.day > 0) &&
                                  duration.minutes > 0 &&
                                  ", "}
                                {duration.minutes > 0 &&
                                  `${duration.minutes} minutes`}
                              </>
                            )}
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="form-control-label">
                          Event End Date{" "}
                          <strong style={{ color: "red" }}>*</strong>
                        </Label>
                        <DatePicker
                          placeholderText="Select End Date"
                          showIcon
                          icon={
                            <i className="fa-solid fa-calendar-day fa-md mt-2" />
                          }
                          toggleCalendarOnIconClick
                          selected={dateForDatePicker.endDate}
                          onChange={(date) => handleDateChange(date, "endDate")}
                          onCalendarClose={() =>
                            handleOnBlur(
                              false,
                              "endDate",
                              dateForDatePicker.endDate
                            )
                          }
                          onBlur={(e) =>
                            handleOnBlur(
                              false,
                              "endDate",
                              dateForDatePicker.endDate
                            )
                          }
                          showTimeSelect
                          timeFormat="hh:mm aa"
                          timeIntervals={15}
                          dateFormat="dd MMM yyyy, hh:mm aa"
                          minDate={minDateForEndDate}
                          maxDate={maxDateForEndDate}
                          wrapperClassName="form-control"
                          className={`form-control ${
                            createCampaignFields.endDate.isValid
                              ? ""
                              : "is-invalid"
                          }`}
                          calendarContainer={(props) => {
                            // Destructure and remove unwanted props,
                            // such as showTime and showTimeSelectOnly or any others as passing to the DOM was showing warnings
                            const {
                              showTime,
                              showTimeSelectOnly,
                              ...restProps
                            } = props;

                            return (
                              <div
                                {...restProps}
                                style={styles.datePickerContainer}
                              />
                            );
                          }}
                          style={styles.datePicker}
                        />
                        {!createCampaignFields.endDate.isValid && (
                          <small className="text-danger">
                            {createCampaignFields.endDate.errMsg}
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          ref={emailConfigRef} //ref used for making the scroll some to the Select
                        >
                          Email Configuration{" "}
                          <strong style={{ color: "red" }}>*</strong>{" "}
                          <span>
                            <i
                              className="fa fa-info-circle"
                              id="tooltip969372949"
                              onClick={(e) => e.preventDefault()}
                              style={styles.cusrorPointer}
                            ></i>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip969372949"
                            >
                              This email configuration will be used to send
                              emails to all sender .
                            </UncontrolledTooltip>
                          </span>
                        </label>
                        <Select
                          options={emailConfigListWithNew}
                          placeholder="Select Email Configuration"
                          name="senderEmail"
                          getOptionLabel={(options) => options.Title}
                          getOptionValue={(options) => options.ID}
                          onChange={(selected, e) =>
                            handleSelectChange(selected, e)
                          }
                          ref={emailInputRef}
                          components={{ Option: CustomOption }}
                          onBlur={() =>
                            handleOnBlur(
                              false,
                              "senderEmail",
                              createCampaignFields.senderEmail.value.Title
                            )
                          }
                          styles={customStyles}
                          value={
                            emailConfigurationList.EmailConfigurations?.find(
                              (option) =>
                                option.ID ===
                                createCampaignFields.senderEmail.value.ID
                            ) || null
                          } // find the selected option based on value
                          isSearchable={true} // enable search functionality
                          className={
                            !createCampaignFields.senderEmail.isValid
                              ? "is-invalid"
                              : null
                          }
                        />
                        {!createCampaignFields.senderEmail.isValid && (
                          <small className="text-danger">
                            {createCampaignFields.senderEmail.errMsg}
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">
                          Organizer Email
                          <strong style={{ color: "red" }}> *</strong>
                        </label>
                        <Input
                          placeholder="Organizer Email"
                          type="text"
                          name="organizerEmail"
                          value={createCampaignFields.organizerEmail.value}
                          onChange={(e) =>
                            handleOnchange(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            handleOnBlur(false, e.target.name, e.target.value)
                          }
                          className={
                            createCampaignFields.organizerEmail.isValid
                              ? ""
                              : "is-invalid"
                          }
                          maxLength={60}
                          autoComplete="off"
                        />
                        {!createCampaignFields.organizerEmail.isValid && (
                          <div className="invalid-feedback">
                            {createCampaignFields.organizerEmail.errMsg}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">
                          Organizer Name
                          <strong style={{ color: "red" }}> *</strong>
                        </label>
                        <Input
                          placeholder="Organizer Name"
                          type="text"
                          name="organizerName"
                          value={createCampaignFields.organizerName.value}
                          onChange={(e) =>
                            handleOnchange(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            handleOnBlur(false, e.target.name, e.target.value)
                          }
                          className={
                            createCampaignFields.organizerName.isValid
                              ? ""
                              : "is-invalid"
                          }
                          maxLength={60}
                          autoComplete="off"
                        />
                        {!createCampaignFields.organizerName.isValid && (
                          <div className="invalid-feedback">
                            {createCampaignFields.organizerName.errMsg}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <label className="form-control-label">
                          Event Type <strong style={{ color: "red" }}>*</strong>
                        </label>
                        <Select
                          options={eventTypeList}
                          placeholder="Select Event Type"
                          name="eventType"
                          onChange={(selected, e) =>
                            handleSelectChange(selected, e)
                          }
                          onBlur={() =>
                            handleOnBlur(
                              false,
                              "eventType",
                              createCampaignFields.eventType.value.value
                            )
                          }
                          styles={customStyles}
                          value={eventTypeList.find(
                            (option) =>
                              option.value ===
                              createCampaignFields.eventType.value.value
                          )} // find the selected option based on value
                          isSearchable={true} // enable search functionality
                          className={
                            !createCampaignFields.eventType.isValid
                              ? "is-invalid"
                              : null
                          }
                        />
                        {!createCampaignFields.eventType.isValid && (
                          <small className="text-danger">
                            {createCampaignFields.eventType.errMsg}
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    {createCampaignFields.eventType.value.value && (
                      <FormGroup className="col-8">
                        <label className="form-control-label">
                          {createCampaignFields.eventType.value.value ===
                          "ONLINE"
                            ? "Event URL"
                            : "Event Location"}
                          <strong style={{ color: "red" }}> *</strong>
                        </label>
                        {createCampaignFields.eventType.value.value ===
                        "ONLINE" ? (
                          <>
                            <Input
                              placeholder={"Enter URL"}
                              type="text"
                              name="eventTypeText"
                              value={createCampaignFields.eventTypeText.value}
                              onChange={(e) =>
                                handleOnchange(e.target.name, e.target.value)
                              }
                              onBlur={() =>
                                handleOnBlur(
                                  false,
                                  "eventTypeText",
                                  createCampaignFields.eventTypeText.value,
                                  "ONLINE"
                                )
                              }
                              className={
                                !createCampaignFields.eventTypeText.isValid
                                  ? "is-invalid"
                                  : null
                              }
                              autoComplete="off"
                              style={{ width: "100%" }}
                            />
                            {!createCampaignFields.eventTypeText.isValid && (
                              <small className="text-danger">
                                {createCampaignFields.eventTypeText.errMsg}
                              </small>
                            )}
                          </>
                        ) : (
                          <div>
                            <APILoader
                              apiKey="AIzaSyC1t9O4_A5tI06Ngxju-0tRJRWzk3nJC9s"
                              solutionChannel="GMP_GCC_placepicker_v1"
                            />

                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                // fontSize: "1rem",
                                gap: "10px",
                              }}
                            >
                              {createCampaignFields.eventTypeText.value &&
                              !editLocation ? (
                                ""
                              ) : (
                                <PlacePicker
                                  country={countries}
                                  // value={console.log(countries)} // Set the value from your state
                                  placeholder="Enter a place to see its address"
                                  onPlaceChange={handlePlaceChange}
                                  onBlur={() =>
                                    handleOnBlur(
                                      false,
                                      "eventTypeText",
                                      createCampaignFields.eventTypeText.value
                                    )
                                  }
                                  autoComplete="off"
                                />
                              )}
                              {!createCampaignFields.eventTypeText.isValid && (
                                <div style={styles.validationError}>
                                  {createCampaignFields.eventTypeText.errMsg}
                                </div>
                              )}
                              <div>
                                {!editLocation &&
                                  createCampaignFields.eventTypeText.value && (
                                    <>
                                      {createCampaignFields.eventTypeText.value}{" "}
                                      <i
                                        className="fa fa-pencil-square"
                                        onClick={handleEditLocation}
                                      />
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </FormGroup>
                    )}
                  </Row>
                  <FormGroup>
                    <label className="form-control-label">
                      Email Subject <strong style={{ color: "red" }}>*</strong>
                    </label>
                    <Input
                      placeholder="Email Subject"
                      type="text"
                      name="emailSubject"
                      value={createCampaignFields.emailSubject.value}
                      onChange={(e) =>
                        handleOnchange(e.target.name, e.target.value)
                      }
                      // onFocus={(e) => handleOnFocus(e.target.name)}
                      onBlur={(e) =>
                        handleOnBlur(true, e.target.name, e.target.value)
                      }
                      className={
                        createCampaignFields.emailSubject.isValid
                          ? ""
                          : "is-invalid"
                      }
                      maxLength={100}
                      autoComplete="off"
                    />
                    {!createCampaignFields.emailSubject.isValid && (
                      <div className="invalid-feedback">
                        {createCampaignFields.emailSubject.errMsg}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    {/* <div
                    data-quill-placeholder="Quill WYSIWYG"
                    data-toggle="quill"
                  /> */}
                    <label className="form-control-label">
                      Email Body <strong style={{ color: "red" }}>*</strong>
                    </label>

                    <EmailEditor
                      createCampaignFields={createCampaignFields}
                      handleOnchange={handleOnchange}
                      handleOnBlur={handleOnBlur}
                    />
                  </FormGroup>
                  {/* toggle buttons to remove unsubscribe and sent via bulk calendar.com link from body */}
                  <div className="d-flex flex-wrap mt--3">
                    <FormGroup className="d-flex">
                      <label className="form-control-label">
                        Remove "Unsubscribe"
                      </label>
                      <Label className="custom-toggle custom-toggle-info ml-2">
                        <Input
                          type="checkbox"
                          checked={
                            createCampaignFields?.removeUnsubscribeFlag
                              .value === "YES"
                              ? true
                              : false
                          }
                          name="removeUnsubscribeFlag"
                          onChange={(e) =>
                            handleOnchange(
                              e.target.name,
                              e.target.checked === true ? "YES" : "NO"
                            )
                          }
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="NO"
                          data-label-on="YES"
                        ></span>
                      </Label>
                    </FormGroup>
                    <div className="text-muted mx-2">|</div>
                    <FormGroup className="d-flex">
                      <label className="form-control-label">
                        Remove "Sent via BulkCalendar.com"
                      </label>
                      <Label className="custom-toggle custom-toggle-info ml-2">
                        <Input
                          type="checkbox"
                          checked={
                            createCampaignFields?.removePoweredByFlag.value ===
                            "YES"
                              ? true
                              : false
                          }
                          name="removePoweredByFlag"
                          onChange={(e) => {
                            handleSentViaBulkChange(e);
                          }}
                          disabled={showLoaderPlanList}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="NO"
                          data-label-on="YES"
                        >
                          {showLoaderPlanList ? (
                            <i className="fa fa-spinner fa-spin fa-sm text-gray p-1" />
                          ) : null}
                        </span>
                      </Label>
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    {campaignCode === null && (
                      <Button
                        color="error"
                        outline
                        onClick={() => {
                          ResetFormFields();
                        }}
                      >
                        Reset Form
                      </Button>
                    )}
                    <Button
                      className="ml-auto"
                      color={isFormValid ? "info" : "default"}
                      disabled={!isFormValid}
                      onClick={() => {
                        // navigate("/a/campaigns/step-2", {
                        //   state: { campaignCode: "c6e2f598-4635-4cc8-b" },
                        // });
                        setShowLoaderOnClick(true);
                        campaignCode !== null
                          ? handleCampaignUpdate()
                          : handleCreateCampaignStep1();
                      }}
                      md={3}
                      sm={6}
                    >
                      {showLoaderOnClick ? (
                        <i className="fa fa-spinner fa-spin fa-lg text-white" />
                      ) : campaignCode !== null ? (
                        "Update"
                      ) : (
                        "Preview"
                      )}
                    </Button>
                  </div>
                  <p className="float-right my-2 text-sm">
                    Please fill all the mandatory (
                    <span className="text-danger">*</span>) information
                  </p>
                </Col>
                <Col md="5" className="mt-3">
                  <Card style={styles.stickyDiv}>
                    <CardHeader>
                      <h3>Attendee Dynamic Parameters</h3>
                      <p
                        className="mb-0 border border-info bg-secondary rounded p-2 b text-black"
                        style={styles.parameterNote}
                      >
                        You can use these dyanamic parameter in Calendar Title,
                        Calendar Description, Email Subject & Email Body. The
                        values for these parameters will be taken from a CSV
                        file or while adding single attendee in next step.
                      </p>
                    </CardHeader>
                    <CardBody>
                      {/* <p className="h4">Calendar Parameters</p>
                      <ul>
                        {calendarParameter.map((item, index) => {
                          return (
                            <li key={index + item.value}>
                              <div style={styles.dynamicParamItem}>
                                <div style={styles.dynamicParamItemRow}>
                                  <p className=" mr-2">{item.label}:</p>{" "}
                                  <p
                                  // className={` ${
                                  //   textInputsFocus.isAnyFocus &&
                                  //   (item.value !== "{email-subject}" ||
                                  //     (item.value === "{email-subject}" &&
                                  //       textInputsFocus.focusFiedName !==
                                  //         "subject"))
                                  //     ? "text-primary font-weight-500"
                                  //     : "default"
                                  // }`}
                                  // style={
                                  //   textInputsFocus.isAnyFocus &&
                                  //   (item.value !== "{email-subject}" ||
                                  //     (item.value === "{email-subject}" &&
                                  //       textInputsFocus.focusFiedName !==
                                  //         "subject"))
                                  //     ? styles.cusrorPointer
                                  //     : {}
                                  // }
                                  // onClick={() => {
                                  //   handleClickDynamicParam(item);
                                  // }}
                                  >
                                    {item.value}
                                  </p>
                                  {copiedText === item.value ? (
                                    <p className="ml-2 text-sm font-weight-bold">
                                      copied!
                                    </p>
                                  ) : (
                                    <div
                                      className="icon-action ml-2"
                                      style={styles.cusrorPointer}
                                      onClick={() =>
                                        handleCopyClick(item.value)
                                      }
                                    >
                                      <i className="fa-regular fa-copy text-info" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                        <li>
                          <div className="d-flex">
                            <p className=" mr-2">
                              {createCampaignFields.eventType.value.value ===
                              "ONLINE"
                                ? "Online Event URL:"
                                : "Event Location:"}
                            </p>{" "}
                            <p
                            // className={` ${
                            //   textInputsFocus.isAnyFocus
                            //     ? "text-primary font-weight-500"
                            //     : "default"
                            // }`}
                            // style={
                            //   textInputsFocus.isAnyFocus
                            //     ? styles.cusrorPointer
                            //     : {}
                            // }
                            // onClick={() => {
                            //   handleClickOnEventType();
                            // }}
                            >
                              {createCampaignFields.eventType.value.value ===
                              "ONLINE"
                                ? `{event-meet-url}`
                                : `{event-location}`}
                            </p>
                            {copiedText === `{event-meet-url}` ||
                            copiedText === `{event-location}` ? (
                              <p className="ml-2 text-sm font-weight-bold">
                                copied!
                              </p>
                            ) : (
                              <div
                                className="icon-action ml-2"
                                style={styles.cusrorPointer}
                                onClick={() =>
                                  handleCopyClick(
                                    createCampaignFields.eventType.value
                                      .value === "ONLINE"
                                      ? `{event-meet-url}`
                                      : `{event-location}`
                                  )
                                }
                              >
                                <i className="fa-regular fa-copy text-info" />
                              </div>
                            )}
                          </div>
                        </li>
                      </ul> */}
                      {/* <p className="h4">Attendee Parameters</p>
                      <p style={{ fontSize: "0.9rem" }}>
                        Parameter values will be taken from a CSV file or while
                        adding single attendee in next step.{" "}
                      </p> */}
                      <ul>
                        {attendeeParameter.map((item, index) => {
                          return (
                            <li key={index + item.value}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <p className=" mr-2">{item.label}:</p>{" "}
                                  <p
                                  // className={` ${
                                  //   textInputsFocus.isAnyFocus &&
                                  //   (item.value !== "{email-subject}" ||
                                  //     (item.value === "{email-subject}" &&
                                  //       textInputsFocus.focusFiedName !==
                                  //         "subject"))
                                  //     ? "text-primary font-weight-500"
                                  //     : "default"
                                  // }`}
                                  // style={
                                  //   textInputsFocus.isAnyFocus &&
                                  //   (item.value !== "{email-subject}" ||
                                  //     (item.value === "{email-subject}" &&
                                  //       textInputsFocus.focusFiedName !==
                                  //         "subject"))
                                  //     ? styles.cusrorPointer
                                  //     : {}
                                  // }
                                  // onClick={() => {
                                  //   handleClickDynamicParam(item);
                                  // }}
                                  >
                                    {item.value}
                                  </p>
                                  {copiedText === item.value ? (
                                    <p className="ml-2 text-sm font-weight-bold">
                                      copied!
                                    </p>
                                  ) : (
                                    <div
                                      className="icon-action ml-2"
                                      style={styles.cusrorPointer}
                                      onClick={() =>
                                        handleCopyClick(item.value)
                                      }
                                    >
                                      <i className="fa-regular fa-copy text-info" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  );
}

export default CreateCampaignStep1;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  iconSpacing: { flexDirection: "row", columnGap: 8 },
  validationError: { color: "red", fontSize: 12 },
  dynamicParamItem: {
    display: "flex",
    flexDirection: "column",
  },
  dynamicParamItemRow: {
    display: "flex",
    flexDirection: "row",
  },
  datePicker: {
    width: "100%",
    display: "flex",
  },
  datePickerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  stepStyle: { fontWeight: "400", color: "gray" },
  parameterNote: {
    fontSize: "0.8rem",
    fontWeight: 500,
    textAlign: "justify",
  },
  stickyDiv: {
    position: "sticky",
    top: 50,
    zIndex: 100, // for sticky element to ensure it remains above the content
  },
};
