import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.1";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Register from "pages/Auth/UserRegistration";
import { Provider } from "react-redux";
import { reducers } from "reducers";
import { configureStore } from "@reduxjs/toolkit";
import ResetPassword from "pages/Auth/ResetPassword";
import UserVerifyEmail from "pages/Auth/UserVerifyEmail";
import React from "react";
import UnsubscribeScreen from "pages/Unsubscriber/Unsubscribe";
import ErrorBoundary from "ErrorBoundries";

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore({ reducer: reducers });
export default store;

const App = () => {
  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const token = localStorage.getItem("p")
    ? JSON.parse(localStorage.getItem("p"))?.token
    : null;
  // Retrieve user info from local storage
  const loginInfo = localStorage.getItem("p")
    ? JSON.parse(localStorage.getItem("p"))
    : null;
  const userName = loginInfo?.name;
  const userEmail = loginInfo?.Email;
  const navigate = useNavigate();
  // React.useEffect(() => {
  //   const publicPaths = [
  //     "/sub-user/registration",
  //     "/user-reset-password",
  //     "/user-verify-email",
  //     "/auth/register",
  //     "/user/unsubscribe",
  //   ];

  //   // Check if the current path contains one of the public paths
  //   if (
  //     !token &&
  //     !publicPaths.some((path) => window.location.pathname.includes(path))
  //   ) {
  //     navigate("/auth/login");
  //   }
  // }, [token, navigate]);

  React.useEffect(() => {
    //added this code for if the sub-user comes on this routs then we will navigate it back to dashboard
    const protectedRoutes = [
      "/a/plans",
      "/a/payments",
      "/a/configurations",
      "/a/sub-users",
      "/a/organization",
      "/a/email/edit",
    ];
    const token = JSON.parse(localStorage.getItem("p"))?.token;
    // Check if the current path contains one of the public paths
    //check condition if the user role is not Admin and in local have token and user try to goes on path which is not accessible
    //It navigate the sub user to the dashboard
    if (
      role !== "TENANT_ADMIN" &&
      token &&
      protectedRoutes.some((path) => window.location.pathname.includes(path))
    ) {
      navigate("/a/dashboard");
    }
  }, [role, window.location.pathname]);

  //when token set means user logged in and then setAttributed in Tawk.to
  React.useEffect(() => {
    if (token) {
      updateTawkVisitorDetails();
    }
  }, [token, userName, userEmail]);

  //fuction to update users name and email upon login in tawk.to
  function updateTawkVisitorDetails() {
    if (
      window.Tawk_API &&
      window.Tawk_API.setAttributes &&
      (userName || userEmail)
    ) {
      window.Tawk_API.setAttributes(
        {
          name: userName || userEmail,
          email: userEmail,
        },
        function (error) {
          if (error) {
            console.error("Error updating details:", error);
          }
        }
      );
    }
  }

  return (
    <Routes>
      <Route path="/a/*" element={<AdminLayout />} />
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="/sub-user/registration/*" element={<Register />} />
      <Route path="/user-reset-password/*" element={<ResetPassword />} />
      <Route path="/user-verify-email/*" element={<UserVerifyEmail />} />
      <Route path="/user/unsubscribe/*" element={<UnsubscribeScreen />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);
