import {
  Card,
  CardBody,
  CardHeader,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { paymentHistory } from "action/Billing/plans";
import CustomPagination from "components/Common/Pagination";
import { downloadInvoice } from "action/Billing/plans";
import Swal from "sweetalert2";
import { convertUTCToLocal } from "components/CommonFunctions";
import Warning from "components/Common/Warning";
import { paymentHistoryList } from "constants/Billing/planConstant";
import { cyanBlue } from "components/Common/constants";

function PaymentHistory() {
  const dispatch = useDispatch();
  const [pageNum, setPageNum] = React.useState(1);
  const paymentHistoryData = useSelector(
    (state) => state.Plans.paymentHistoryList
  );
  const [showListAlternative, setShowListAlternative] = React.useState({
    loader: true,
    noRecordsFound: false,
  });

  React.useEffect(() => {
    dispatch({ type: paymentHistoryList, payload: {} });
    paymentHistory(dispatch, pageNum);
  }, [dispatch, pageNum]);

  React.useEffect(() => {
    if (
      paymentHistoryData.listPaymentHistory &&
      paymentHistoryData.listPaymentHistory?.length > 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
    } else if (
      paymentHistoryData &&
      paymentHistoryData.listPaymentHistory?.length === 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
    } else if (paymentHistoryData.code) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
        error: true,
      });
    }
  }, [paymentHistoryData]);

  const handleDownloadInvoice = async (invoiceID, pdfName) => {
    const res = await downloadInvoice(invoiceID);
    if (res.data) {
      // Create a Blob from the response data
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      // Create a temporary link to trigger the download
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", pdfName); // Filename for the downloaded PDF
      document.body.appendChild(link);
      link.click();
      // Clean up by removing the link and revoking the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      Swal.fire({
        title: "Something Went Wrong!",
        text: `Failed to download the invoice.`,
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  return (
    <>
      {/* <SimpleHeader name="Camping" /> */}
      <Warning />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Payment History</h3>
          </CardHeader>
          <CardBody>
            {showListAlternative.loader ? (
              <div style={styles.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={styles.loaderColor}
                />
              </div>
            ) : showListAlternative.error ? (
              <div style={styles.loaderContainer}>
                Something went wrong please try after some time.
              </div>
            ) : paymentHistoryData.listPaymentHistory?.length === 0 ? (
              <div style={styles.loaderContainer}>No Records Found</div>
            ) : (
              <ToolkitProvider
                data={paymentHistoryData.listPaymentHistory || []}
                keyField="downloadedId"
                columns={[
                  {
                    dataField: "planName",
                    text: "Plan",
                    align: "center",
                    headerAlign: "center",
                    sort: true,
                  },
                  {
                    dataField: "createdAt",
                    text: "Date",
                    align: "center",
                    headerAlign: "center",
                    formatter: (cell) => {
                      return convertUTCToLocal(cell);
                    },
                    sort: true,
                  },
                  {
                    dataField: "amount",
                    text: "Paid Amount",
                    align: "right",
                    headerAlign: "right",
                    sort: true,
                    formatter: (cell, row) => {
                      return (
                        <>
                          {row.currency === "INR"
                            ? "₹"
                            : row.currency === "USD"
                            ? "$"
                            : null}
                          {cell}
                        </>
                      );
                    },
                  },
                  {
                    dataField: "allocatedCredits",
                    text: "Credits",
                    align: "right",
                    headerAlign: "right",
                    sort: true,
                  },
                  {
                    dataField: "requestedBy",
                    text: "Download Invoice",
                    align: "center",
                    headerAlign: "center",
                    sort: true,
                    formatter: (cell, row, index) => (
                      <div key={row.ID}>
                        <UncontrolledTooltip target={`tooltipEdit${index}`}>
                          Download Invoice
                        </UncontrolledTooltip>

                        <i
                          id={`tooltipEdit${index}`}
                          className="fa fa-download text-lg text-info"
                          onClick={() =>
                            handleDownloadInvoice(row.invoiceID, row.pdfName)
                          }
                        ></i>
                      </div>
                    ),
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                    />
                    <div>
                      <CustomPagination
                        totalPages={Math.ceil(
                          paymentHistoryData.totalRecords /
                            paymentHistoryData.noOfRecordsPerPage
                        )}
                        setPageNum={setPageNum}
                        activePage={pageNum}
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default PaymentHistory;
const styles = {
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  loaderColor: { color: cyanBlue },
};
